// Generated by BUCKLESCRIPT VERSION 4.0.18, PLEASE EDIT WITH CARE

import * as Css from "bs-css/src/Css.js";
import * as React from "react";
import * as React$ReactTemplate from "../vendor/React.bs.js";

var component = React$ReactTemplate.statelessComponent("Space");

function make(h, w, _children) {
  return /* record */[
          /* debugName */component[/* debugName */0],
          /* reactClassInternal */component[/* reactClassInternal */1],
          /* handedOffState */component[/* handedOffState */2],
          /* willReceiveProps */component[/* willReceiveProps */3],
          /* didMount */component[/* didMount */4],
          /* didUpdate */component[/* didUpdate */5],
          /* willUnmount */component[/* willUnmount */6],
          /* willUpdate */component[/* willUpdate */7],
          /* shouldUpdate */component[/* shouldUpdate */8],
          /* render */(function (_self) {
              var height = h !== undefined ? Css.height(Css.px(h)) : Css.height(/* auto */-1065951377);
              var width = w !== undefined ? Css.width(Css.px(w)) : Css.width(/* auto */-1065951377);
              return React.createElement("div", {
                          className: Css.style(/* :: */[
                                height,
                                /* :: */[
                                  width,
                                  /* [] */0
                                ]
                              ])
                        });
            }),
          /* initialState */component[/* initialState */10],
          /* retainedProps */component[/* retainedProps */11],
          /* reducer */component[/* reducer */12],
          /* jsElementWrapped */component[/* jsElementWrapped */13]
        ];
}

export {
  component ,
  make ,
  
}
/* component Not a pure module */
