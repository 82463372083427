// Generated by BUCKLESCRIPT VERSION 4.0.18, PLEASE EDIT WITH CARE

import * as Css from "bs-css/src/Css.js";

function whenSm(rules) {
  return Css.media("(min-width: 576px)", rules);
}

function whenMd(rules) {
  return Css.media("(min-width: 768px)", rules);
}

function whenLg(rules) {
  return Css.media("(min-width: 992px)", rules);
}

function whenXl(rules) {
  return Css.media("(min-width: 1200px)", rules);
}

function whenHover(rules) {
  return Css.media("(hover: hover)", /* :: */[
              Css.hover(rules),
              /* [] */0
            ]);
}

export {
  whenSm ,
  whenMd ,
  whenLg ,
  whenXl ,
  whenHover ,
  
}
/* Css Not a pure module */
