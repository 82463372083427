// Generated by BUCKLESCRIPT VERSION 4.0.18, PLEASE EDIT WITH CARE

import * as Css from "bs-css/src/Css.js";
import * as Theme$ReactTemplate from "../shared/Theme.bs.js";
import * as KarmillaBoldWoff from "../static/fonts/Karmilla-Bold.woff";
import * as KarmillaRegularWoff from "../static/fonts/Karmilla-Regular.woff";
import * as PlayfairDisplayBoldWoff from "../static/fonts/PlayfairDisplay-Bold.woff";

Css.fontFace("Karmilla", /* :: */[
      Css.url(KarmillaRegularWoff),
      /* [] */0
    ], /* normal */812216871, /* `num */[
      5496390,
      400
    ], /* () */0);

Css.fontFace("Karmilla", /* :: */[
      Css.url(KarmillaBoldWoff),
      /* [] */0
    ], /* normal */812216871, /* `num */[
      5496390,
      700
    ], /* () */0);

Css.fontFace("PlayfairDisplay", /* :: */[
      Css.url(PlayfairDisplayBoldWoff),
      /* [] */0
    ], /* normal */812216871, /* `num */[
      5496390,
      700
    ], /* () */0);

Css.$$global("html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video", /* :: */[
      Css.borderWidth(Css.px(0)),
      /* :: */[
        Css.fontSize(Css.pct(100)),
        /* :: */[
          Css.margin(Css.px(0)),
          /* :: */[
            Css.padding(Css.px(0)),
            /* :: */[
              Css.verticalAlign(/* baseline */287825029),
              /* :: */[
                Css.unsafe("font", "inherit"),
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]);

Css.$$global("blockquote, q", /* :: */[
      Css.unsafe("quotes", "none"),
      /* [] */0
    ]);

Css.$$global("blockquote:before, blockquote:after, q:before, q:after", /* :: */[
      Css.unsafe("content", "''"),
      /* :: */[
        Css.unsafe("content", "none"),
        /* [] */0
      ]
    ]);

Css.$$global("ol, ul", /* :: */[
      Css.listStyleType(/* none */-922086728),
      /* [] */0
    ]);

Css.$$global("table", /* :: */[
      Css.borderCollapse(/* collapse */-996847251),
      /* :: */[
        Css.borderSpacing(Css.px(0)),
        /* [] */0
      ]
    ]);

Css.$$global("html", /* :: */[
      Css.boxSizing(/* borderBox */9307263),
      /* :: */[
        Css.cursor(/* default */465819841),
        /* :: */[
          Css.unsafe("textSizeAdjust", "100%"),
          /* :: */[
            Css.unsafe("tabSize", "2"),
            /* [] */0
          ]
        ]
      ]
    ]);

Css.$$global("body", /* :: */[
      Css.backgroundColor(Theme$ReactTemplate.whiteColor),
      /* :: */[
        Css.display(/* flex */-1010954439),
        /* :: */[
          Css.flexDirection(/* column */-963948842),
          /* :: */[
            Css.fontFamily(Theme$ReactTemplate.baseFontFamily),
            /* :: */[
              Css.letterSpacing(Css.em(-0.02)),
              /* :: */[
                Css.lineHeight(/* `abs */[
                      4845682,
                      1.65
                    ]),
                /* :: */[
                  Css.unsafe("WebkitFontSmoothing", "antialiased"),
                  /* :: */[
                    Css.unsafe("MozOsxFontSmoothing", "grayscale"),
                    /* [] */0
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

Css.$$global("*, ::before, ::after", /* :: */[
      Css.backgroundRepeat(/* noRepeat */-695430532),
      /* :: */[
        Css.boxSizing(/* inherit_ */-601204732),
        /* [] */0
      ]
    ]);

Css.$$global("::before, ::after", /* :: */[
      Css.textDecoration(/* inherit_ */-601204732),
      /* :: */[
        Css.verticalAlign(/* inherit_ */-601204732),
        /* [] */0
      ]
    ]);

Css.$$global("::selection", /* :: */[
      Css.backgroundColor(Css.hex("b3d4fc")),
      /* :: */[
        Css.color(Css.black),
        /* :: */[
          Css.unsafe("textShadow", "none"),
          /* [] */0
        ]
      ]
    ]);

Css.$$global("::-webkit-input-placeholder", /* :: */[
      Css.color(/* currentColor */292050538),
      /* :: */[
        Css.opacity(0.54),
        /* [] */0
      ]
    ]);

Css.$$global("#root", /* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.flexDirection(/* column */-963948842),
        /* :: */[
          Css.flexGrow(1),
          /* :: */[
            Css.overflowX(/* hidden */-862584982),
            /* :: */[
              Css.width(Css.pct(100)),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

Css.$$global("code, kbd, pre, samp", /* :: */[
      Css.fontFamily(Theme$ReactTemplate.monospaceFontFamily),
      /* [] */0
    ]);

Css.$$global("img, canvas, iframe, video, svg, select, textarea", /* :: */[
      Css.maxWidth(Css.pct(100)),
      /* [] */0
    ]);

Css.$$global("audio, canvas, iframe, img, svg, video", /* :: */[
      Css.unsafe("touchAction", "manipulation"),
      /* [] */0
    ]);

Css.$$global("textarea", /* :: */[
      Css.unsafe("resize", "vertical"),
      /* [] */0
    ]);

Css.$$global("svg:not([fill])", /* :: */[
      Css.SVG[/* fill */0](/* currentColor */292050538),
      /* [] */0
    ]);

Css.$$global("[aria-busy=\"true\"]", /* :: */[
      Css.cursor(/* progress */103479213),
      /* [] */0
    ]);

Css.$$global("[aria-controls]", /* :: */[
      Css.cursor(/* pointer */-786317123),
      /* [] */0
    ]);

Css.$$global("[aria-disabled=\"true\"], [disabled]", /* :: */[
      Css.cursor(/* notAllowed */939907157),
      /* [] */0
    ]);

Css.$$global("[aria-hidden=\"false\"][hidden]:not(:focus)", /* :: */[
      Css.display(/* inherit_ */-601204732),
      /* :: */[
        Css.position(/* absolute */-1013592457),
        /* :: */[
          Css.unsafe("clip", "rect(0,0,0,0)"),
          /* [] */0
        ]
      ]
    ]);

export {
  
}
/*  Not a pure module */
