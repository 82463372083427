// Generated by BUCKLESCRIPT VERSION 4.0.18, PLEASE EDIT WITH CARE

import * as ReasonReact from "reason-react/src/ReasonReact.js";

var statelessComponent = ReasonReact.statelessComponent;

var statelessComponentWithRetainedProps = ReasonReact.statelessComponentWithRetainedProps;

var reducerComponent = ReasonReact.reducerComponent;

var reducerComponentWithRetainedProps = ReasonReact.reducerComponentWithRetainedProps;

var element = ReasonReact.element;

var wrapReasonForJs = ReasonReact.wrapReasonForJs;

var createDomElement = ReasonReact.createDomElement;

var wrapJsForReason = ReasonReact.wrapJsForReason;

var Router = ReasonReact.Router;

export {
  statelessComponent ,
  statelessComponentWithRetainedProps ,
  reducerComponent ,
  reducerComponentWithRetainedProps ,
  element ,
  wrapReasonForJs ,
  createDomElement ,
  wrapJsForReason ,
  Router ,
  
}
/* ReasonReact Not a pure module */
