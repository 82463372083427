// Generated by BUCKLESCRIPT VERSION 4.0.18, PLEASE EDIT WITH CARE

import * as ReactDOMRe from "reason-react/src/ReactDOMRe.js";
import * as ReasonReact from "reason-react/src/ReasonReact.js";
import * as App$ReactTemplate from "./App.bs.js";

ReactDOMRe.hydrateToElementWithId(ReasonReact.element(undefined, undefined, App$ReactTemplate.make(/* array */[])), "root");

export {
  
}
/*  Not a pure module */
