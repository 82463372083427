// Generated by BUCKLESCRIPT VERSION 4.0.18, PLEASE EDIT WITH CARE

import * as Css from "bs-css/src/Css.js";
import * as ReactDOMRe from "reason-react/src/ReactDOMRe.js";
import * as React$ReactTemplate from "../vendor/React.bs.js";

var component = React$ReactTemplate.statelessComponent("View");

var baseStyle = Css.style(/* :: */[
      Css.alignItems(/* stretch */-162316795),
      /* :: */[
        Css.borderWidth(Css.px(0)),
        /* :: */[
          Css.borderStyle(/* solid */12956715),
          /* :: */[
            Css.boxSizing(/* borderBox */9307263),
            /* :: */[
              Css.display(/* flex */-1010954439),
              /* :: */[
                Css.flexBasis(/* auto */-1065951377),
                /* :: */[
                  Css.flexDirection(/* column */-963948842),
                  /* :: */[
                    Css.flexShrink(0),
                    /* :: */[
                      Css.margin(Css.px(0)),
                      /* :: */[
                        Css.padding(Css.px(0)),
                        /* :: */[
                          Css.position(/* relative */903134412),
                          /* :: */[
                            Css.minHeight(Css.px(0)),
                            /* :: */[
                              Css.minWidth(Css.px(0)),
                              /* [] */0
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

function make(role, className, children) {
  return /* record */[
          /* debugName */component[/* debugName */0],
          /* reactClassInternal */component[/* reactClassInternal */1],
          /* handedOffState */component[/* handedOffState */2],
          /* willReceiveProps */component[/* willReceiveProps */3],
          /* didMount */component[/* didMount */4],
          /* didUpdate */component[/* didUpdate */5],
          /* willUnmount */component[/* willUnmount */6],
          /* willUpdate */component[/* willUpdate */7],
          /* shouldUpdate */component[/* shouldUpdate */8],
          /* render */(function (_self) {
              var className$1 = className !== undefined ? Css.merge(/* :: */[
                      baseStyle,
                      /* :: */[
                        className,
                        /* [] */0
                      ]
                    ]) : baseStyle;
              if (role !== undefined) {
                switch (role) {
                  case 0 : 
                      return ReactDOMRe.createElementVariadic("article", {
                                  className: className$1
                                }, children);
                  case 1 : 
                      return ReactDOMRe.createElementVariadic("header", {
                                  className: className$1
                                }, children);
                  case 2 : 
                      return ReactDOMRe.createElementVariadic("aside", {
                                  className: className$1
                                }, children);
                  case 3 : 
                      return ReactDOMRe.createElementVariadic("footer", {
                                  className: className$1
                                }, children);
                  case 4 : 
                      return ReactDOMRe.createElementVariadic("form", {
                                  className: className$1
                                }, children);
                  case 5 : 
                      return ReactDOMRe.createElementVariadic("ul", {
                                  className: className$1
                                }, children);
                  case 6 : 
                      return ReactDOMRe.createElementVariadic("li", {
                                  className: className$1
                                }, children);
                  case 7 : 
                      return ReactDOMRe.createElementVariadic("main", {
                                  className: className$1
                                }, children);
                  case 8 : 
                      return ReactDOMRe.createElementVariadic("nav", {
                                  className: className$1
                                }, children);
                  case 9 : 
                      return ReactDOMRe.createElementVariadic("section", {
                                  className: className$1
                                }, children);
                  
                }
              } else {
                return ReactDOMRe.createElementVariadic("div", {
                            className: className$1
                          }, children);
              }
            }),
          /* initialState */component[/* initialState */10],
          /* retainedProps */component[/* retainedProps */11],
          /* reducer */component[/* reducer */12],
          /* jsElementWrapped */component[/* jsElementWrapped */13]
        ];
}

export {
  component ,
  baseStyle ,
  make ,
  
}
/* component Not a pure module */
