// Generated by BUCKLESCRIPT VERSION 4.0.18, PLEASE EDIT WITH CARE

import * as Css from "bs-css/src/Css.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as $$String from "bs-platform/lib/es6/string.js";
import * as ReactDOMRe from "reason-react/src/ReactDOMRe.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as ReasonReact from "reason-react/src/ReasonReact.js";
import * as React$ReactTemplate from "../vendor/React.bs.js";

var component = React$ReactTemplate.statelessComponent("Link");

var externalLinkRe = (/^(https?:\/\/|mailto:)/);

function make(url, href, activeClassName, className, $staropt$star, onClick, children) {
  var matchSubroutes = $staropt$star !== undefined ? $staropt$star : false;
  return /* record */[
          /* debugName */component[/* debugName */0],
          /* reactClassInternal */component[/* reactClassInternal */1],
          /* handedOffState */component[/* handedOffState */2],
          /* willReceiveProps */component[/* willReceiveProps */3],
          /* didMount */component[/* didMount */4],
          /* didUpdate */component[/* didUpdate */5],
          /* willUnmount */component[/* willUnmount */6],
          /* willUpdate */component[/* willUpdate */7],
          /* shouldUpdate */component[/* shouldUpdate */8],
          /* render */(function (param) {
              var isActive = Belt_Option.getWithDefault(Belt_Option.map(Belt_Option.map(url, (function (url) {
                              return "/" + $$String.concat("/", url[/* path */0]);
                            })), (function (path) {
                          if (matchSubroutes) {
                            if ((path + "/").startsWith(href)) {
                              return true;
                            } else {
                              return path.startsWith(href);
                            }
                          } else if (path === href) {
                            return true;
                          } else {
                            return path + "/" === href;
                          }
                        })), false);
              var className$1;
              if (className !== undefined) {
                var className$2 = className;
                className$1 = activeClassName !== undefined && isActive ? Css.merge(/* :: */[
                        className$2,
                        /* :: */[
                          activeClassName,
                          /* [] */0
                        ]
                      ]) : className$2;
              } else {
                className$1 = activeClassName !== undefined && isActive ? activeClassName : undefined;
              }
              var tmp = {
                href: href,
                onClick: (function ($$event) {
                    var match = $$event.metaKey;
                    var match$1 = $$event.ctrlKey;
                    if (match || match$1) {
                      return /* () */0;
                    } else {
                      if (!externalLinkRe.test(href)) {
                        $$event.preventDefault();
                        ReasonReact.Router[/* push */0](href);
                      }
                      if (onClick !== undefined) {
                        return Curry._1(onClick, $$event);
                      } else {
                        return /* () */0;
                      }
                    }
                  })
              };
              if (className$1 !== undefined) {
                tmp.className = Caml_option.valFromOption(className$1);
              }
              return ReactDOMRe.createElementVariadic("a", Caml_option.some(tmp), children);
            }),
          /* initialState */component[/* initialState */10],
          /* retainedProps */component[/* retainedProps */11],
          /* reducer */component[/* reducer */12],
          /* jsElementWrapped */component[/* jsElementWrapped */13]
        ];
}

export {
  component ,
  externalLinkRe ,
  make ,
  
}
/* component Not a pure module */
