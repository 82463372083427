// Generated by BUCKLESCRIPT VERSION 4.0.18, PLEASE EDIT WITH CARE

import * as Css from "bs-css/src/Css.js";

var primaryColor = Css.hex("d3e8e1");

var textColor = Css.hex("626174");

var titleColor = Css.hex("3c3c4e");

var whiteColor = Css.hex("fcfcfc");

var baseFontSize = Css.px(18);

var baseFontFamily = "Karmilla, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Helvetica, Arial, sans-serif";

var monospaceFontFamily = "SFMono-Regular, Consolas, \"Liberation Mono\", Menlo, Courier, monospace";

export {
  baseFontFamily ,
  monospaceFontFamily ,
  primaryColor ,
  textColor ,
  titleColor ,
  whiteColor ,
  baseFontSize ,
  
}
/* primaryColor Not a pure module */
