// Generated by BUCKLESCRIPT VERSION 4.0.18, PLEASE EDIT WITH CARE

import * as Css from "bs-css/src/Css.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as ReasonReact from "reason-react/src/ReasonReact.js";
import * as View$ReactTemplate from "./View.bs.js";
import * as React$ReactTemplate from "../vendor/React.bs.js";

var component = React$ReactTemplate.statelessComponent("FlatList");

var horizontal = Css.style(/* :: */[
      Css.flexDirection(/* row */5693978),
      /* :: */[
        Css.overflowY(/* auto */-1065951377),
        /* [] */0
      ]
    ]);

var vertical = Css.style(/* :: */[
      Css.flexDirection(/* column */-963948842),
      /* :: */[
        Css.overflowX(/* auto */-1065951377),
        /* [] */0
      ]
    ]);

var Styles = /* module */[
  /* horizontal */horizontal,
  /* vertical */vertical
];

function make(className, data, $staropt$star, keyExtractor, renderItem, separatorElement, _children) {
  var horizontal$1 = $staropt$star !== undefined ? $staropt$star : false;
  return /* record */[
          /* debugName */component[/* debugName */0],
          /* reactClassInternal */component[/* reactClassInternal */1],
          /* handedOffState */component[/* handedOffState */2],
          /* willReceiveProps */component[/* willReceiveProps */3],
          /* didMount */component[/* didMount */4],
          /* didUpdate */component[/* didUpdate */5],
          /* willUnmount */component[/* willUnmount */6],
          /* willUpdate */component[/* willUpdate */7],
          /* shouldUpdate */component[/* shouldUpdate */8],
          /* render */(function (_self) {
              var separatorElement$1 = Belt_Option.getWithDefault(separatorElement, null);
              var baseStyle = horizontal$1 ? horizontal : vertical;
              var className$1 = Belt_Option.getWithDefault(className, "");
              return ReasonReact.element(undefined, undefined, View$ReactTemplate.make(/* List */5, Css.merge(/* :: */[
                                  baseStyle,
                                  /* :: */[
                                    className$1,
                                    /* [] */0
                                  ]
                                ]), /* array */[Belt_Array.mapWithIndex(data, (function (i, item) {
                                      var match = data.length === (i + 1 | 0);
                                      var separatorElement$2 = match ? null : separatorElement$1;
                                      return React.createElement(React.Fragment, {
                                                  key: Curry._2(keyExtractor, i, item)
                                                }, Curry._2(renderItem, i, item), separatorElement$2);
                                    }))]));
            }),
          /* initialState */component[/* initialState */10],
          /* retainedProps */component[/* retainedProps */11],
          /* reducer */component[/* reducer */12],
          /* jsElementWrapped */component[/* jsElementWrapped */13]
        ];
}

export {
  component ,
  Styles ,
  make ,
  
}
/* component Not a pure module */
