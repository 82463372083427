// Generated by BUCKLESCRIPT VERSION 4.0.18, PLEASE EDIT WITH CARE

import * as Css from "bs-css/src/Css.js";
import * as Block from "bs-platform/lib/es6/block.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as ReasonReact from "reason-react/src/ReasonReact.js";
import * as Link$ReactTemplate from "./shared/Link.bs.js";
import * as View$ReactTemplate from "./shared/View.bs.js";
import * as Media$ReactTemplate from "./utils/Media.bs.js";
import * as React$ReactTemplate from "./vendor/React.bs.js";
import * as Space$ReactTemplate from "./shared/Space.bs.js";
import * as Theme$ReactTemplate from "./shared/Theme.bs.js";
import * as Content$ReactTemplate from "./shared/Content.bs.js";
import * as FlatList$ReactTemplate from "./shared/FlatList.bs.js";
import * as CssGlobal$ReactTemplate from "./utils/CssGlobal.bs.js";
import * as IllustrationSvg from "./static/media/illustration.svg";

((require('normalize.css')));

var component = React$ReactTemplate.reducerComponent("App");

var flexed = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* [] */0
    ]);

var container = Css.style(/* :: */[
      Css.alignSelf(/* center */98248149),
      /* :: */[
        Css.maxWidth(Css.px(1200)),
        /* :: */[
          Css.width(Css.pct(100)),
          /* [] */0
        ]
      ]
    ]);

var block = Css.style(/* :: */[
      Css.paddingLeft(Css.px(16)),
      /* :: */[
        Css.paddingRight(Css.px(16)),
        /* :: */[
          Media$ReactTemplate.whenMd(/* :: */[
                Css.paddingLeft(Css.px(32)),
                /* :: */[
                  Css.paddingRight(Css.px(32)),
                  /* [] */0
                ]
              ]),
          /* [] */0
        ]
      ]
    ]);

var text = Css.style(/* :: */[
      Css.fontSize(Theme$ReactTemplate.baseFontSize),
      /* :: */[
        Css.color(Theme$ReactTemplate.textColor),
        /* [] */0
      ]
    ]);

var title = Css.style(/* :: */[
      Css.color(Theme$ReactTemplate.titleColor),
      /* :: */[
        Css.display(/* flex */-1010954439),
        /* :: */[
          Css.fontFamily("PlayfairDisplay"),
          /* :: */[
            Css.fontWeight(/* `num */[
                  5496390,
                  700
                ]),
            /* :: */[
              Css.lineHeight(/* `abs */[
                    4845682,
                    1.2
                  ]),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var smallTitle = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.fontSize(Theme$ReactTemplate.baseFontSize),
        /* :: */[
          Css.color(Theme$ReactTemplate.titleColor),
          /* :: */[
            Css.lineHeight(/* `abs */[
                  4845682,
                  1.4
                ]),
            /* :: */[
              Css.fontWeight(/* bold */-1055161979),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var hero = Css.style(/* :: */[
      Css.backgroundColor(Theme$ReactTemplate.primaryColor),
      /* :: */[
        Css.margin(Css.px(16)),
        /* :: */[
          Css.borderRadius(Css.px(2)),
          /* [] */0
        ]
      ]
    ]);

var header = Css.style(/* :: */[
      Css.paddingLeft(Css.px(4)),
      /* :: */[
        Css.paddingRight(Css.px(4)),
        /* :: */[
          Css.paddingTop(Css.px(24)),
          /* :: */[
            Css.paddingBottom(Css.px(24)),
            /* :: */[
              Media$ReactTemplate.whenMd(/* :: */[
                    Css.alignItems(/* center */98248149),
                    /* :: */[
                      Css.flexDirection(/* row */5693978),
                      /* :: */[
                        Css.justifyContent(/* spaceBetween */516682146),
                        /* [] */0
                      ]
                    ]
                  ]),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var menuNav = Css.style(/* :: */[
      Css.marginTop(Css.px(8)),
      /* :: */[
        Css.flexShrink(1),
        /* :: */[
          Media$ReactTemplate.whenMd(/* :: */[
                Css.marginTop(Css.px(0)),
                /* [] */0
              ]),
          /* [] */0
        ]
      ]
    ]);

var menuList = Css.style(/* :: */[
      Css.flexDirection(/* row */5693978),
      /* :: */[
        Css.flexWrap(/* wrap */-822134326),
        /* :: */[
          Css.textTransform(/* uppercase */-415330030),
          /* :: */[
            Media$ReactTemplate.whenMd(/* :: */[
                  Css.justifyContent(/* flexEnd */924268066),
                  /* [] */0
                ]),
            /* [] */0
          ]
        ]
      ]
    ]);

var menuLink = Css.style(/* :: */[
      Css.color(Theme$ReactTemplate.textColor),
      /* :: */[
        Css.position(/* relative */903134412),
        /* :: */[
          Css.textDecoration(/* none */-922086728),
          /* :: */[
            Css.zIndex(1),
            /* :: */[
              Css.letterSpacing(/* normal */812216871),
              /* :: */[
                Css.opacity(0.9),
                /* :: */[
                  Css.marginRight(Css.px(16)),
                  /* :: */[
                    Css.after(/* :: */[
                          Css.unsafe("content", "''"),
                          /* :: */[
                            Css.position(/* absolute */-1013592457),
                            /* :: */[
                              Css.borderRadius(Css.px(2)),
                              /* :: */[
                                Css.top(Css.px(0)),
                                /* :: */[
                                  Css.bottom(Css.em(0.25)),
                                  /* :: */[
                                    Css.left(Css.em(-0.125)),
                                    /* :: */[
                                      Css.right(Css.em(-0.125)),
                                      /* :: */[
                                        Css.verticalAlign(/* baseline */287825029),
                                        /* :: */[
                                          Css.zIndex(-1),
                                          /* :: */[
                                            Css.backgroundColor(Css.hex("f2cc6c")),
                                            /* :: */[
                                              Css.transform(Css.scaleY(0)),
                                              /* :: */[
                                                Css.transformOrigin(Css.pct(50), Css.pct(100)),
                                                /* :: */[
                                                  Css.transitionProperty("transform"),
                                                  /* :: */[
                                                    Css.transitionDuration(250),
                                                    /* :: */[
                                                      Css.transitionTimingFunction(/* ease */-1022587922),
                                                      /* [] */0
                                                    ]
                                                  ]
                                                ]
                                              ]
                                            ]
                                          ]
                                        ]
                                      ]
                                    ]
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ]),
                    /* :: */[
                      Media$ReactTemplate.whenHover(/* :: */[
                            Css.after(/* :: */[
                                  Css.transform(Css.scaleY(0.4)),
                                  /* [] */0
                                ]),
                            /* [] */0
                          ]),
                      /* :: */[
                        Media$ReactTemplate.whenMd(/* :: */[
                              Css.marginLeft(Css.px(32)),
                              /* :: */[
                                Css.marginRight(Css.px(0)),
                                /* [] */0
                              ]
                            ]),
                        /* [] */0
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var row = Css.style(/* :: */[
      Media$ReactTemplate.whenMd(/* :: */[
            Css.flexDirection(/* row */5693978),
            /* [] */0
          ]),
      /* [] */0
    ]);

var introContainer = Css.style(/* :: */[
      Css.paddingTop(Css.px(32)),
      /* :: */[
        Css.paddingBottom(Css.px(56)),
        /* :: */[
          Media$ReactTemplate.whenMd(/* :: */[
                Css.flex(4),
                /* :: */[
                  Css.paddingTop(Css.vw(6.5)),
                  /* :: */[
                    Css.paddingBottom(Css.vw(12)),
                    /* [] */0
                  ]
                ]
              ]),
          /* :: */[
            Media$ReactTemplate.whenXl(/* :: */[
                  Css.paddingTop(Css.px(80)),
                  /* :: */[
                    Css.paddingBottom(Css.px(132)),
                    /* [] */0
                  ]
                ]),
            /* [] */0
          ]
        ]
      ]
    ]);

var introTitle = Css.merge(/* :: */[
      title,
      /* :: */[
        Css.style(/* :: */[
              Css.fontSize(Css.px(48)),
              /* [] */0
            ]),
        /* [] */0
      ]
    ]);

var introParagraph = Css.merge(/* :: */[
      text,
      /* :: */[
        Css.style(/* :: */[
              Css.marginBottom(Css.px(8)),
              /* [] */0
            ]),
        /* [] */0
      ]
    ]);

var imageContainer = Css.style(/* :: */[
      Css.marginLeft(Css.px(-72)),
      /* :: */[
        Css.marginRight(Css.px(-72)),
        /* :: */[
          Css.marginBottom(Css.vw(-2.5)),
          /* :: */[
            Media$ReactTemplate.whenMd(/* :: */[
                  Css.flex(6),
                  /* :: */[
                    Css.marginLeft(Css.px(0)),
                    /* :: */[
                      Css.marginRight(Css.vw(-7.5)),
                      /* :: */[
                        Css.marginBottom(Css.vw(-1.5)),
                        /* [] */0
                      ]
                    ]
                  ]
                ]),
            /* :: */[
              Media$ReactTemplate.whenXl(/* :: */[
                    Css.marginLeft(Css.px(72)),
                    /* :: */[
                      Css.marginRight(Css.px(-88)),
                      /* :: */[
                        Css.marginBottom(Css.px(-16)),
                        /* [] */0
                      ]
                    ]
                  ]),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var image = Css.style(/* :: */[
      Media$ReactTemplate.whenMd(/* :: */[
            Css.position(/* absolute */-1013592457),
            /* :: */[
              Css.bottom(Css.px(0)),
              /* :: */[
                Css.right(Css.px(0)),
                /* :: */[
                  Css.maxHeight(Css.px(384)),
                  /* [] */0
                ]
              ]
            ]
          ]),
      /* [] */0
    ]);

var page = Css.style(/* :: */[
      Css.paddingTop(Css.px(48)),
      /* :: */[
        Media$ReactTemplate.whenMd(/* :: */[
              Css.paddingTop(Css.px(64)),
              /* [] */0
            ]),
        /* [] */0
      ]
    ]);

var sectionTitle = Css.merge(/* :: */[
      title,
      /* :: */[
        Css.style(/* :: */[
              Css.fontSize(Css.px(32)),
              /* [] */0
            ]),
        /* [] */0
      ]
    ]);

var tools = Css.style(/* :: */[
      Css.unsafe("WebkitOverflowScrolling", "touch"),
      /* :: */[
        Css.unsafe("scrollSnapType", "x mandatory"),
        /* :: */[
          Media$ReactTemplate.whenMd(/* :: */[
                Css.paddingLeft(Css.px(16)),
                /* :: */[
                  Css.paddingRight(Css.px(16)),
                  /* [] */0
                ]
              ]),
          /* [] */0
        ]
      ]
    ]);

function toolItem(last) {
  return Css.style(/* :: */[
              Css.alignItems(/* center */98248149),
              /* :: */[
                Css.color(Theme$ReactTemplate.textColor),
                /* :: */[
                  Css.display(/* flex */-1010954439),
                  /* :: */[
                    Css.flexDirection(/* column */-963948842),
                    /* :: */[
                      Css.borderRadius(Css.px(8)),
                      /* :: */[
                        Css.textDecoration(/* none */-922086728),
                        /* :: */[
                          Css.paddingLeft(Css.px(16)),
                          /* :: */[
                            Css.paddingRight(Css.px(last ? 16 : 0)),
                            /* :: */[
                              Css.unsafe("scrollSnapAlign", "start"),
                              /* [] */0
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]);
}

function toolLogo(bgColor) {
  return Css.style(/* :: */[
              Css.backgroundColor(bgColor),
              /* :: */[
                Css.borderRadius(Css.px(6)),
                /* :: */[
                  Css.height(Css.px(80)),
                  /* :: */[
                    Css.width(Css.px(80)),
                    /* :: */[
                      Css.transforms(/* :: */[
                            Css.scaleX(1),
                            /* :: */[
                              Css.scaleY(1),
                              /* [] */0
                            ]
                          ]),
                      /* :: */[
                        Css.transformOrigin(Css.pct(50), Css.pct(50)),
                        /* :: */[
                          Css.transitionProperty("transform"),
                          /* :: */[
                            Css.transitionDuration(250),
                            /* :: */[
                              Css.transitionTimingFunction(/* easeIn */138027891),
                              /* :: */[
                                Media$ReactTemplate.whenHover(/* :: */[
                                      Css.transforms(/* :: */[
                                            Css.scaleX(0.95),
                                            /* :: */[
                                              Css.scaleY(0.95),
                                              /* [] */0
                                            ]
                                          ]),
                                      /* [] */0
                                    ]),
                                /* [] */0
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]);
}

var toolName = Css.style(/* :: */[
      Css.fontSize(Css.px(14)),
      /* :: */[
        Css.marginTop(Css.px(6)),
        /* :: */[
          Css.marginBottom(Css.px(16)),
          /* [] */0
        ]
      ]
    ]);

var corporateItem = Css.style(/* :: */[
      Css.maxWidth(Css.px(546)),
      /* [] */0
    ]);

var itemLine = Css.style(/* :: */[
      Css.alignItems(/* center */98248149),
      /* :: */[
        Css.flexDirection(/* row */5693978),
        /* :: */[
          Css.marginBottom(Css.px(4)),
          /* [] */0
        ]
      ]
    ]);

function corporateLogo(bgColor) {
  return Css.style(/* :: */[
              Css.backgroundColor(bgColor),
              /* :: */[
                Css.borderRadius(Css.px(6)),
                /* :: */[
                  Css.height(Css.px(24)),
                  /* :: */[
                    Css.width(Css.px(24)),
                    /* [] */0
                  ]
                ]
              ]
            ]);
}

var corporateLink = Css.merge(/* :: */[
      smallTitle,
      /* :: */[
        Css.style(/* :: */[
              Css.display(/* flex */-1010954439),
              /* :: */[
                Css.textDecoration(/* none */-922086728),
                /* [] */0
              ]
            ]),
        /* [] */0
      ]
    ]);

var footer = Css.merge(/* :: */[
      container,
      /* :: */[
        block,
        /* :: */[
          Css.style(/* :: */[
                Css.paddingTop(Css.px(16)),
                /* :: */[
                  Css.paddingBottom(Css.px(16)),
                  /* :: */[
                    Css.textAlign(/* center */98248149),
                    /* :: */[
                      Css.lineHeight(/* `abs */[
                            4845682,
                            1.4
                          ]),
                      /* :: */[
                        Css.fontSize(Css.px(14)),
                        /* :: */[
                          Css.color(Theme$ReactTemplate.textColor),
                          /* :: */[
                            Css.opacity(0.75),
                            /* [] */0
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ]),
          /* [] */0
        ]
      ]
    ]);

var Styles = /* module */[
  /* flexed */flexed,
  /* container */container,
  /* block */block,
  /* text */text,
  /* title */title,
  /* smallTitle */smallTitle,
  /* hero */hero,
  /* header */header,
  /* menuNav */menuNav,
  /* menuList */menuList,
  /* menuLink */menuLink,
  /* row */row,
  /* introContainer */introContainer,
  /* introTitle */introTitle,
  /* introParagraph */introParagraph,
  /* imageContainer */imageContainer,
  /* image */image,
  /* page */page,
  /* sectionTitle */sectionTitle,
  /* tools */tools,
  /* toolItem */toolItem,
  /* toolLogo */toolLogo,
  /* toolName */toolName,
  /* corporateItem */corporateItem,
  /* itemLine */itemLine,
  /* corporateLogo */corporateLogo,
  /* corporateLink */corporateLink,
  /* footer */footer
];

function make(_children) {
  return /* record */[
          /* debugName */component[/* debugName */0],
          /* reactClassInternal */component[/* reactClassInternal */1],
          /* handedOffState */component[/* handedOffState */2],
          /* willReceiveProps */component[/* willReceiveProps */3],
          /* didMount */(function (self) {
              return Curry._1(self[/* send */3], /* SwapEmail */0);
            }),
          /* didUpdate */component[/* didUpdate */5],
          /* willUnmount */component[/* willUnmount */6],
          /* willUpdate */component[/* willUpdate */7],
          /* shouldUpdate */component[/* shouldUpdate */8],
          /* render */(function (self) {
              return React.createElement(React.Fragment, undefined, ReasonReact.element(undefined, undefined, View$ReactTemplate.make(undefined, hero, /* array */[ReasonReact.element(undefined, undefined, View$ReactTemplate.make(undefined, Css.merge(/* :: */[
                                                container,
                                                /* :: */[
                                                  block,
                                                  /* [] */0
                                                ]
                                              ]), /* array */[
                                            ReasonReact.element(undefined, undefined, View$ReactTemplate.make(/* Banner */1, header, /* array */[
                                                      React.createElement("h1", {
                                                            className: smallTitle
                                                          }, Content$ReactTemplate.siteTitle),
                                                      ReasonReact.element(undefined, undefined, View$ReactTemplate.make(/* Navigation */8, menuNav, /* array */[ReasonReact.element(undefined, undefined, View$ReactTemplate.make(/* List */5, menuList, /* array */[
                                                                          ReasonReact.element(undefined, undefined, View$ReactTemplate.make(/* ListItem */6, undefined, /* array */[ReasonReact.element(undefined, undefined, Link$ReactTemplate.make(undefined, "mailto:" + self[/* state */1][/* email */0], undefined, menuLink, undefined, undefined, /* array */["contact"]))])),
                                                                          ReasonReact.element(undefined, undefined, View$ReactTemplate.make(/* ListItem */6, undefined, /* array */[ReasonReact.element(undefined, undefined, Link$ReactTemplate.make(undefined, Content$ReactTemplate.githubUrl, undefined, menuLink, undefined, undefined, /* array */["github"]))])),
                                                                          ReasonReact.element(undefined, undefined, View$ReactTemplate.make(/* ListItem */6, undefined, /* array */[ReasonReact.element(undefined, undefined, Link$ReactTemplate.make(undefined, Content$ReactTemplate.twitterUrl, undefined, menuLink, undefined, undefined, /* array */["twitter"]))]))
                                                                        ]))]))
                                                    ])),
                                            ReasonReact.element(undefined, undefined, View$ReactTemplate.make(undefined, row, /* array */[
                                                      ReasonReact.element(undefined, undefined, View$ReactTemplate.make(undefined, introContainer, /* array */[
                                                                React.createElement("span", {
                                                                      className: introTitle
                                                                    }, "Hello !"),
                                                                ReasonReact.element(undefined, undefined, Space$ReactTemplate.make(16, undefined, /* array */[])),
                                                                Belt_Array.mapWithIndex(Content$ReactTemplate.introParagraphs, (function (i, content) {
                                                                        return React.createElement("p", {
                                                                                    key: "intro-p-" + String(i),
                                                                                    className: introParagraph
                                                                                  }, content);
                                                                      }))
                                                              ])),
                                                      ReasonReact.element(undefined, undefined, View$ReactTemplate.make(undefined, imageContainer, /* array */[React.createElement("img", {
                                                                      className: image,
                                                                      alt: "website main illustration",
                                                                      src: IllustrationSvg
                                                                    })]))
                                                    ]))
                                          ]))])), ReasonReact.element(undefined, undefined, View$ReactTemplate.make(/* Main */7, Css.merge(/* :: */[
                                      container,
                                      /* :: */[
                                        page,
                                        /* [] */0
                                      ]
                                    ]), /* array */[
                                  ReasonReact.element(undefined, undefined, View$ReactTemplate.make(/* Region */9, undefined, /* array */[
                                            React.createElement("h2", {
                                                  className: Css.merge(/* :: */[
                                                        block,
                                                        /* :: */[
                                                          sectionTitle,
                                                          /* [] */0
                                                        ]
                                                      ])
                                                }, "Things I work with"),
                                            ReasonReact.element(undefined, undefined, Space$ReactTemplate.make(24, undefined, /* array */[])),
                                            ReasonReact.element(undefined, undefined, FlatList$ReactTemplate.make(Css.merge(/* :: */[
                                                          text,
                                                          /* :: */[
                                                            tools,
                                                            /* [] */0
                                                          ]
                                                        ]), Content$ReactTemplate.tools, true, (function (_i, tool) {
                                                        return tool[/* name */0];
                                                      }), (function (i, tool) {
                                                        var isLast = Content$ReactTemplate.tools.length === (i + 1 | 0);
                                                        return ReasonReact.element(undefined, undefined, View$ReactTemplate.make(/* ListItem */6, toolItem(isLast), /* array */[
                                                                        ReasonReact.element(undefined, undefined, Link$ReactTemplate.make(undefined, tool[/* href */3], undefined, flexed, undefined, undefined, /* array */[React.createElement("img", {
                                                                                        className: toolLogo(tool[/* bgColor */1]),
                                                                                        alt: tool[/* name */0] + " logo",
                                                                                        src: tool[/* logoSrc */2]
                                                                                      })])),
                                                                        React.createElement("span", {
                                                                              className: toolName
                                                                            }, tool[/* name */0])
                                                                      ]));
                                                      }), undefined, /* array */[]))
                                          ])),
                                  ReasonReact.element(undefined, undefined, Space$ReactTemplate.make(32, undefined, /* array */[])),
                                  ReasonReact.element(undefined, undefined, View$ReactTemplate.make(/* Region */9, block, /* array */[
                                            React.createElement("h2", {
                                                  className: sectionTitle
                                                }, "Things I worked on"),
                                            ReasonReact.element(undefined, undefined, Space$ReactTemplate.make(24, undefined, /* array */[])),
                                            ReasonReact.element(undefined, undefined, FlatList$ReactTemplate.make(text, Content$ReactTemplate.experiences, undefined, (function (_i, xp) {
                                                        return xp[/* title */0];
                                                      }), (function (_i, xp) {
                                                        var match = xp[/* href */2];
                                                        return ReasonReact.element(undefined, undefined, View$ReactTemplate.make(/* ListItem */6, corporateItem, /* array */[
                                                                        ReasonReact.element(undefined, undefined, View$ReactTemplate.make(undefined, itemLine, /* array */[
                                                                                  ReasonReact.element(undefined, undefined, View$ReactTemplate.make(undefined, corporateLogo(xp[/* bgColor */3]), /* array */[xp[/* logoElement */4]])),
                                                                                  ReasonReact.element(undefined, undefined, Space$ReactTemplate.make(undefined, 12, /* array */[])),
                                                                                  match !== undefined ? ReasonReact.element(undefined, undefined, Link$ReactTemplate.make(undefined, match, undefined, corporateLink, undefined, undefined, /* array */[xp[/* title */0]])) : React.createElement("span", {
                                                                                          className: corporateLink
                                                                                        }, xp[/* title */0])
                                                                                ])),
                                                                        React.createElement("p", undefined, xp[/* description */1])
                                                                      ]));
                                                      }), Caml_option.some(ReasonReact.element(undefined, undefined, Space$ReactTemplate.make(24, undefined, /* array */[]))), /* array */[]))
                                          ])),
                                  ReasonReact.element(undefined, undefined, Space$ReactTemplate.make(48, undefined, /* array */[])),
                                  ReasonReact.element(undefined, undefined, View$ReactTemplate.make(/* Region */9, block, /* array */[
                                            React.createElement("h2", {
                                                  className: sectionTitle
                                                }, "Activities I enjoy"),
                                            ReasonReact.element(undefined, undefined, Space$ReactTemplate.make(24, undefined, /* array */[])),
                                            ReasonReact.element(undefined, undefined, FlatList$ReactTemplate.make(text, Content$ReactTemplate.activities, undefined, (function (_i, activity) {
                                                        return activity[/* name */0];
                                                      }), (function (_i, activity) {
                                                        return ReasonReact.element(undefined, undefined, View$ReactTemplate.make(/* ListItem */6, itemLine, /* array */[
                                                                        activity[/* iconElement */1],
                                                                        ReasonReact.element(undefined, undefined, Space$ReactTemplate.make(undefined, 12, /* array */[])),
                                                                        activity[/* name */0]
                                                                      ]));
                                                      }), undefined, /* array */[]))
                                          ])),
                                  ReasonReact.element(undefined, undefined, Space$ReactTemplate.make(48, undefined, /* array */[])),
                                  ReasonReact.element(undefined, undefined, View$ReactTemplate.make(/* Region */9, block, /* array */[
                                            React.createElement("h2", {
                                                  className: sectionTitle
                                                }, "Talks I gave"),
                                            ReasonReact.element(undefined, undefined, Space$ReactTemplate.make(24, undefined, /* array */[])),
                                            ReasonReact.element(undefined, undefined, FlatList$ReactTemplate.make(text, Content$ReactTemplate.talks, undefined, (function (_i, talk) {
                                                        return talk[/* title */0];
                                                      }), (function (_i, talk) {
                                                        var match = talk[/* href */2];
                                                        return React.createElement("li", {
                                                                    className: itemLine
                                                                  }, React.createElement("span", {
                                                                        "aria-hidden": true
                                                                      }, "▸ "), match !== undefined ? ReasonReact.element(undefined, undefined, Link$ReactTemplate.make(undefined, match, undefined, text, undefined, undefined, /* array */[talk[/* title */0]])) : React.createElement("span", {
                                                                          className: text
                                                                        }, talk[/* title */0]), " @ " + talk[/* event */1]);
                                                      }), undefined, /* array */[]))
                                          ])),
                                  ReasonReact.element(undefined, undefined, Space$ReactTemplate.make(56, undefined, /* array */[]))
                                ])), ReasonReact.element(undefined, undefined, View$ReactTemplate.make(/* ContentInfo */3, footer, /* array */[React.createElement("small", undefined, "website written using Reason & ReasonReact")])));
            }),
          /* initialState */(function (param) {
              return /* record */[/* email */""];
            }),
          /* retainedProps */component[/* retainedProps */11],
          /* reducer */(function (action, _state) {
              return /* Update */Block.__(0, [/* record */[/* email */Content$ReactTemplate.emailAddress]]);
            }),
          /* jsElementWrapped */component[/* jsElementWrapped */13]
        ];
}

export {
  component ,
  Styles ,
  make ,
  
}
/*  Not a pure module */
